import { colorsRGB, icons } from "@util/constants";
import { Container, P } from "@util/standard";
import React, { ReactNode, useState } from "react";
import { Collapse } from "react-collapse";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  noBorderTop?: boolean;
  noBorderBottom?: boolean;
  text: string;
  borderColor?: string;
  whiteVersion?: boolean;
}

const StyledContainer = styled(Container) <{
  noBorderTop?: boolean;
  noBorderBottom?: boolean;
  borderColor?: string;
  whiteVersion?: boolean;
}>`
  border-top: 1px solid ${props => (props.whiteVersion ? colorsRGB.white(0.2) : colorsRGB.black(0.2))};
  border-bottom: 1px solid ${props => (props.whiteVersion ? colorsRGB.white(0.2) : colorsRGB.black(0.2))};

  ${({ borderColor }) =>
    borderColor &&
    `
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};
  `}
  flex-direction: column;
  cursor: pointer;
  ${({ noBorderTop }) => noBorderTop && ` border-top: none;`};
  ${({ noBorderBottom }) => noBorderBottom && ` border-bottom: none;`};
`;

const StyledCollapse = styled(Collapse)`
  border-top: 1px solid ${colorsRGB.black(0.2)};
  border-bottom: 1px solid ${colorsRGB.black(0.2)};
`;

const Icon = styled.img<{ flipped: boolean; }>`
  ${({ flipped }) => (flipped ? `transform: rotateZ(180deg);` : `transform: rotateZ(0deg);`)};
  width: 15px;
  transition: all 0.2s;
`;

function Accordion({ children, noBorderTop, noBorderBottom, borderColor, text, whiteVersion }: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledContainer
      width="100%"
      onClick={() => setIsExpanded(!isExpanded)}
      noBorderBottom={noBorderBottom}
      noBorderTop={noBorderTop}
      borderColor={borderColor}
      whiteVersion={whiteVersion}
    >
      <Container justifyContent="space-between">
        <P color={whiteVersion ? "white" : "black"}>{text}</P>
        <Icon flipped={isExpanded} src={whiteVersion ? icons.arrowWhite : icons.arrow} />
      </Container>
      <StyledCollapse isOpened={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
        {children}
      </StyledCollapse>
    </StyledContainer>
  );
}

export default Accordion;
